import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
  Image,
  Location,
  SocialMedia,
  Content,
  Map,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"contact-container"}
          >
            <Grid.Column width={16}>
              <div className={"contact-form-container"}>
                <ContactForm
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      label: "name",
                      placeholder: "Full Name",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "email",
                      placeholder: "Email",
                      type: "input",
                      inputType: "email",
                      required: true,
                    },
                    {
                      label: "phone",
                      placeholder: "Phone",
                      type: "input",
                      required: true,
                    },
                    {
                      displayLabel: "Optional",
                      label: "message",
                      placeholder: "Message",
                      type: "textarea",
                      required: false,
                    },
                    {
                      label: "sick",
                      displayLabel:
                        "In order to keep both guest, staff and their families healthy we ask you to rebook if you are currently or have been recently sick. This includes coughing, sneezing, fever, sore throat but is not limited to. This does include being positive for Covid 19. Please select YES if you are healthy and feeling great, NO if you need to reschedule due to not feeling well.",
                      type: "select",
                      required: true,
                      options: [
                        {
                          label: "Yes Im healthy and able to come to my appt",
                          value: "Yes Im healthy and able to come to my appt",
                        },
                        {
                          label: "Im not feeling 100% and need to reschedule",
                          value: "Im not feeling 100% and need to reschedule",
                        },
                      ],
                    },
                    {
                      label: "capacityRules",
                      displayLabel:
                        "Capacity Rules: due to safety and limited space, only guests receiving services will be permitted in the salon, parent or guardians may accompany a minor child to their service. No siblings/or additional persons will be permitted to attend another sibling's/or person's appointment. If a parent/ guardian has an appointment they may not bring a child/or additional guest with them to their appointment. Please select YES if you are healthy and feeling great, NO if you agree to capacity and space rules, NO if you need to reschedule due to childcare.",
                      type: "select",
                      required: true,
                      options: [
                        {
                          label: "Yes I agree to capacity and space rules",
                          value: "Yes I agree to capacity and space rules",
                        },
                        {
                          label: "Im not feeling 100% and need to reschedule",
                          value: "Im not feeling 100% and need to reschedule",
                        },
                      ],
                    },
                    {
                      label: "cancellation",
                      displayLabel:
                        "Cancellation/ no show and deposit rules 48hr noticed required to cancel or reschedule an appointment Any deposit for an appointment over 1hr WILL NOT be credited towards future services if guest NO SHOWS or cancelled with less that 48hr notice prior. Any deposit for an appointment WILL be credited to future appointments if rescheduled or cancelled 48hrs prior.",
                      type: "select",
                      required: true,
                      options: [
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ],
                    },
                  ]}
                  buttonText={"Submit"}
                  centeredHeaders={false}
                  hideOptIn={false}
                  className={""}
                  fluidButton={false}
                  header={
                    <Grid className={"contact-form-header"}>
                      <Grid.Column width={16} textAlign={"center"}>
                        <Image
                          src={getComponentFiles({
                            components: fishermanBusinessWebsitePage.components,
                            componentName: "HeroElement",
                            numToSelect: 1,
                          })}
                          background={true}
                          className={"contact-form-header-image"}
                        >
                          <div>
                            <Header
                              as={"h1"}
                              content={getComponentContentNodeContent({
                                components:
                                  fishermanBusinessWebsitePage.components,
                                componentId: "contact_header",
                                defaultValue: "Contact",
                              })}
                            />
                            <Location
                              address={{
                                street:
                                  fishermanBusiness.primaryLocation.street,
                                city: fishermanBusiness.primaryLocation.city,
                                state: fishermanBusiness.primaryLocation.state,
                                zip: fishermanBusiness.primaryLocation.zipCode,
                              }}
                            />
                            <SocialMedia
                              socialMediaValues={fishermanBusiness.socialMedia}
                              buttonType={"rect"}
                              groupButtons={false}
                              showLabels={false}
                            />
                          </div>
                        </Image>
                        <Content>
                          <Content.Markup
                            isMarkdown={true}
                            textAlign={"center"}
                            content={getComponentContentNodeContent({
                              components:
                                fishermanBusinessWebsitePage.components,
                              componentId: "contact_form_description",
                            })}
                          />
                        </Content>
                      </Grid.Column>
                    </Grid>
                  }
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={false}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                  event={{ category: "Contact", action: "Submit Inquiry" }}
                />
                <div className={"contact-form-footer"}>
                  <div>
                    <Map
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                      businessName={fishermanBusiness.name}
                    />
                  </div>
                  <div>
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        street
        city
        state
        zipCode
        _id
        hours {
          day
          open
          close
          label
        }
      }
      socialMedia {
        link
        type
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
